export const homeData = {
    introduce: {
        label: '关于我们',
        value: '四川鑫川丰越科技有限公司成立于2019年11月，注册地为中国（四川）自由贸易试验区成都高新区，注册资本为1000万元。公司主要从事管道无人机智能化巡检服务、无人机信息技术研发、无人机信息系统集成服务，是一家集科研、开发、服务于一体的专业化、科技型无人机巡检公司。',
    },
    business: {
        label: '公司主要业务',
        value: '无人机，我们一直都是专业的'
    },
    qualification: {
        label: '公司资质',
        value: '公司营业执照、无人驾驶航空器经营许可证'
    },
    demand: {
        label: '市场需求-响应十四五号召',
        value: ''
    },
    certification: {
        label: '公司世标认证证书',
        value: '（无人机航拍巡线环境管理体系、职业健康安全管理体系、质量管理体系认证，符合行业标准）'
    },
    contact: {
        address: '成都市高新区天府大道北段3栋1单元1102',
        phone: '028-8861-4508',
        number: '马飞137-7814-5878',
        time: '周一--周五: 09:30 – 18:30'
    }
}

export const businessData = [
    {
        label: "日常巡检",
        value: "日常定期的管道巡查、排查是否有违反《管道保护法》等行为存在。",
        code: "one",
    },
    {
        label: "正射影像",
        value: "采集油气管道周边地理信息。",
        code: "two",
    },
    {
        label: "专项巡检",
        value: "油气泄漏以及地质灾害排查。",
        code: "three",
    },
    {
        label: "应急巡检",
        value: "发生灾害时，应急响应救援",
        code: "four",
    },
]