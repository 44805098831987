<template>
  <div>
    <Home />
  </div>
</template>

<script>
import Home from "./pages/home";

export default {
  name: "",
  components: { Home },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped></style>
