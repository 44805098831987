<template>
  <div class="home">
    <div class="home-introduce">
      <div class="home-introduce_text">
        <div class="home-introduce_text-title">
          {{ homeList.introduce.label }}
        </div>
        <div class="home-introduce_text-bottom">
          <div class="home-introduce_text-bottom-left">
            <div class="left-title">我们是<br />鑫川丰越</div>
            <div class="left-content">
              {{ homeList.introduce.value }}
            </div>
          </div>
          <div class="home-introduce_text-bottom-right">
            <img src="../../assets/image/home.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="home-show">
      <img src="../../assets/image/home-bgc_02.jpg" alt="" />
    </div>

    <div class="home-business">
      <div class="home-business_title">
        <div class="home-business_title-main">
          {{ homeList.business.label }}
        </div>
        <div class="home-business_title-line">
          <span></span>
        </div>
        <div class="home-business_title-vice">
          {{ homeList.business.value }}
        </div>
      </div>
      <div class="home-business_content">
        <div
          class="home-business_content-item"
          v-for="item in businessList"
          :key="item.code"
        >
          <!-- 红圈加标题 -->
          <div
            :class="['item-plane', `plane-${item.code}`]"
            v-if="activeBusiness == item.code"
          >
            <div class="plane-title">{{ item.label }}</div>
          </div>
          <!-- 白色小圈 -->
          <div
            :class="['item-pie', `pie-${item.code}`]"
            v-else
            @click="onClick(item.code)"
          ></div>
          <div :class="['item-text', `text-${item.code}`]">
            <!-- 白圈的标题 -->
            <div class="item-text-top" v-if="activeBusiness != item.code">
              {{ item.label }}
            </div>
            <div class="item-text-bottom">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-qualification">
      <div class="home-qualification_title">
        {{ homeList.qualification.label }}
      </div>
      <div class="home-qualification_subtitle">
        {{ homeList.qualification.value }}
      </div>
      <div class="home-qualification_image">
        <img
          class="img-license"
          src="../../assets/image/home-license.jpg"
          alt=""
        />
        <img
          class="img-permission"
          src="../../assets/image/home-permission.png"
          alt=""
        />
      </div>
    </div>

    <div class="home-demand">
      <div class="home-demand_title">
        <div class="home-demand_title-main">{{ homeList.demand.label }}</div>
        <div class="home-demand_title-line">
          <span></span>
        </div>
        <!-- 内容未确定  暂时空着 -->
        <div class="home-demand_title-vice"></div>
      </div>
      <div class="home-demand_content">
        <div :class="['home-demand_content-item', 'item-one']">
          <div class="item-title">安全可控</div>
          <div class="item-text">
            恶劣地形条件区域，无人机仍然可以抵达并安全完成巡检；<br /><br />
            高含硫管道实现机械化与智能化巡检，降低人工危害风险；<br /><br />
            无人机巡检技术服务整体委托，安全管理难度降低；<br /><br />
            无人机行业要求严格，安全管理规范。
          </div>
        </div>
        <div :class="['home-demand_content-item', 'item-two']">
          <div class="item-title">降本增效</div>
          <div class="item-text">
            有效降低人员管理等成本，增强巡检效果。
          </div>
        </div>
        <div :class="['home-demand_content-item', 'item-three']">
          <div class="item-title">智能化</div>
          <div class="item-text">
            无人机巡检所有数据可追溯，巡检成果更加直观
          </div>
        </div>
      </div>
    </div>

    <div class="home-certification">
      <div class="home-certification_title">
        {{ homeList.certification.label }}
      </div>
      <div class="home-certification_subtitle">
        {{ homeList.certification.value }}
      </div>
      <div class="home-certification_image">
        <div>
          <img
            class="img-one"
            src="../../assets/image/home-certification-one.jpg"
            alt=""
          />
        </div>
        <div>
          <img
            class="img-two"
            src="../../assets/image/home-certification-two.jpg"
            alt=""
          />
        </div>
        <div>
          <img
            class="img-three"
            src="../../assets/image/home-certification-three.jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="home-contact">
      <span :class="['home-contact_item', 'item-one']">
        {{ homeList.contact.address }}
      </span>
      <span :class="['home-contact_item', 'item-one']">
        {{ homeList.contact.phone }}
      </span>
      <span :class="['home-contact_item', 'item-one']">
        {{ homeList.contact.number }}
      </span>
      <span :class="['home-contact_item', 'item-one']">
        {{ homeList.contact.time }}
      </span>
    </div>

    <div class="home-footer">
      <div class="home-footer_row-01">
        <a href="https://beian.miit.gov.cn" target="_blank"
          >蜀ICP备20020877号-2</a
        >
      </div>
      <div class="home-footer_row-02">
        <a target="_blank" href="https://beian.miit.gov.cn">
          <img src="../../assets/image/record_02.png" alt="" />
          <span>川公网安备 51019002004136号</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { homeData, businessData } from "./helper";

export default {
  name: "home",
  components: {},
  props: {},
  data() {
    return {
      activeBusiness: "one",
      homeList: homeData,
      businessList: businessData,
    };
  },
  computed: {},
  methods: {
    onClick(code) {
      this.activeBusiness = code;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.home {
  &-introduce {
    height: 1807px;
    background: url("../../assets/image/home-bgc_01.jpg") no-repeat;
    background-size: cover;
    position: relative;
    &_text {
      width: 100vw;
      height: 700px;
      color: #ffffff;
      position: absolute;
      left: 0;
      bottom: 0;
      &-title {
        width: 1920px;
        font-size: 42px;
        font-weight: normal;
        color: #dfe5e8;
        letter-spacing: 6px;
        text-align: center;
      }
      &-bottom {
        display: flex;
        position: relative;
        &-left {
          width: 582px;
          height: 258px;
          position: absolute;
          left: 535px;
          margin-top: 200px;
          .left-title {
            font-size: 44px;
            font-weight: normal;
            color: #ffffff;
          }
          .left-content {
            width: 423px;
            height: 136px;
            font-size: 16px;
            font-weight: normal;
            line-height: 30px;
            color: #ffffff;
            opacity: 0.5;
          }
        }
        &-right {
          width: 747px;
          height: 431px;
          position: absolute;
          left: 1180px;
          margin-top: 115px;
          & > img {
            width: 710px;
          }
        }
      }
    }
  }

  &-show {
    height: 400px;
    & > img {
      height: 400px;
    }
  }

  &-business {
    height: 835px;
    background: url(../../assets/image/home-bgc_03.jpg) no-repeat;
    background-size: cover;
    color: #ffffff;
    &_title {
      width: 1920px;
      height: 258px;
      text-align: center;
      &-main {
        padding-top: 100px;
        font-size: 42px;
        font-weight: normal;
        letter-spacing: 6px;
        color: #f0f3f2;
      }
      &-line {
        display: flex;
        justify-content: center;
        margin: 40px 0px;
        & > span {
          display: block;
          width: 88px;
          height: 1px;
          background-color: #a1a1a1;
        }
      }
      &-vice {
        font-size: 16px;
        font-weight: normal;
        color: #a1a1a1;
      }
    }
    &_content {
      width: 1920px;
      height: 500px;
      position: relative;
      &-item {
        .item-pie {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          background-color: #ffffff;
          box-shadow: 0px 0px 37px 0px #ffffff;
          border: solid 6px rgba(255, 255, 255, 0.19);
          position: absolute;
          cursor: pointer;
        }
        .pie-one {
          top: 157px;
          left: 530px;
        }
        .pie-two {
          top: 75%;
          left: 40%;
        }
        .pie-three {
          top: 17%;
          left: 53%;
        }
        .pie-four {
          top: 74%;
          left: 66%;
        }
        .item-text {
          width: 242px;
          text-align: center;
          position: absolute;
          &-top {
            font-size: 20px;
            font-weight: normal;
            color: #ffffff;
            padding: 10px 0px;
          }
          &-bottom {
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            font-size: 16px;
            line-height: 30px;
            color: #ffffff;
            padding: 5px;
          }
        }
        .text-one {
          top: 40%;
          left: 22%;
        }
        .text-two {
          top: 85%;
          left: 35%;
        }
        .text-three {
          top: 27%;
          left: 47%;
        }
        .text-four {
          top: 85%;
          left: 61%;
        }
        .item-plane {
          width: 332px;
          height: 242px;
          text-align: center;
          background: url(../../assets/image/home-plane.png) no-repeat;
          position: absolute;
          .plane-title {
            font-size: 24px;
            font-weight: normal;
            color: #ffffff;
            margin-top: 140px;
          }
        }
        .plane-one {
          top: -6%;
          left: 20%;
        }
        .plane-two {
          top: 38%;
          left: 32%;
        }
        .plane-three {
          top: -20%;
          left: 45%;
        }
        .plane-four {
          top: 37%;
          left: 58%;
        }
      }
    }
  }

  &-qualification {
    width: 1920px;
    height: 876px;
    text-align: center;
    background: url(../../assets/image/home-bgc_04.jpg) no-repeat;
    background-size: cover;
    &_title {
      font-size: 42px;
      font-weight: normal;
      line-height: 50px;
      letter-spacing: 6px;
      color: #212331;
      padding-top: 110px;
    }
    &_subtitle {
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;

      color: #5d5d5d;
      margin-top: 70px;
    }
    &_image {
      display: flex;
      justify-content: center;
      & > img {
        width: 470px;
        margin: 120px 30px;
      }
    }
  }

  &-demand {
    width: 1920px;
    height: 796px;
    background: url(../../assets/image/home-bgc_05.jpg) no-repeat;
    background-size: cover;
    &_title {
      height: 258px;
      text-align: center;
      &-main {
        padding-top: 100px;
        font-size: 42px;
        font-weight: normal;
        letter-spacing: 6px;
        color: #f0f3f2;
      }
      &-line {
        display: flex;
        justify-content: center;
        margin: 40px 0px;
        & > span {
          display: block;
          width: 88px;
          height: 1px;
          background-color: #a1a1a1;
        }
      }
      &-vice {
        font-size: 16px;
        font-weight: normal;
        color: #a1a1a1;
      }
    }
    &_content {
      display: flex;
      position: relative;
      &-item {
        position: absolute;
        .item-title {
          font-size: 16px;
          font-weight: normal;
          line-height: 50px;
          color: #00bcd4;
        }
        .item-text {
          width: 312px;
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          color: #a1a1a1;
          margin-top: 40px;
        }
      }
      .item-one {
        left: 21%;
        top: 163px;
      }
      .item-two {
        left: 852px;
        top: 163px;
      }
      .item-three {
        left: 1276px;
        top: 163px;
      }
    }
  }

  &-certification {
    width: 1920px;
    height: 965px;
    text-align: center;
    background: url(../../assets/image/home-bgc_06.jpg) no-repeat;
    background-size: cover;
    &_title {
      font-size: 42px;
      font-weight: normal;
      line-height: 50px;
      letter-spacing: 6px;
      color: #212331;
      padding-top: 110px;
    }
    &_subtitle {
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      color: #5d5d5d;
      margin-top: 70px;
    }
    &_image {
      display: flex;
      justify-content: center;
      & > div {
        width: 362px;
        height: 468px;
        background: url(../../assets/image/Back.png) no-repeat;
        margin: 0px 30px;
        margin-top: 160px;
        position: relative;
        & > img {
          width: 322px;
          position: absolute;
          left: 20px;
          top: -60px;
        }
      }
    }
  }

  &-contact {
    width: 1920px;
    height: 653px;
    background: url(../../assets/image/home-bgc_07.jpg) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    &_item {
      vertical-align: text-top;
      display: inline-block;
      width: 217px;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      color: #99a9b5;
      margin: 0px 40px;
      margin-top: 540px;
    }
  }

  &-footer {
    width: 1920px;
    // height: 91px;
    text-align: center;
    background-color: #000000;
    box-shadow: inset 0px 1px 0px 0px rgba(57, 64, 80, 0.75);
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
    color: #99a9b5;
    padding: 10px 0px;
    &_row-01 {
      a {
        color: #939393;
        text-decoration: none;
      }
    }
    &_row-02 {
      a {
        display: inline-block;
        color: #939393;
        text-decoration: none;
        & > img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
